<template>
  <div id="about">
    <div class="base-info">
      <div class="base-info-left">
        <h1>第三届中国新能源和节能环保产业博览会</h1>
        <div class="base-info-row">
          <div class="time">
            <img src="../../assets/img/time.png" />
            <span>时间：2024年7月4-6日</span>
          </div>
          <div class="address">
            <img src="../../assets/img/address.png" />
            <span>地点：合肥滨湖国际会展中心</span>
          </div>
        </div>
        <p>{{ about }}</p>
        <ReserveBtn />
      </div>
      <!-- <div class="video-box" v-if="videoUrl">
        <video
          id="my-video1"
          class="video-js"
          controls
          preload="auto"
          playsinline
          webkit-playsinline
          poster="../../assets/img/video_bj.jpg"
        >
          <source :src="videoUrl" type="video/mp4" />
        </video>
        <img v-show="!isPaly" src="../../assets/img/play_icon.png" />
      </div> -->
    </div>
    <Statistics />
  </div>
</template>
<script>
import videoJS from "video.js";
import Statistics from "@/components/statistics.vue";
import ReserveBtn from "@/components/reserve-btn.vue";
import { getMiceInfo } from "../../core/miceInfo";
export default {
  name: "index-about",
  components: {
    ReserveBtn,
    Statistics,
  },
  data() {
    return {
      videoUrl: "",
      isPaly: false,
      about: "",
    };
  },
  watch: {
    videoUrl: {
      handler(val) {
        if (val) {
          setTimeout(() => {
            this.loadVideo();
          });
        }
      },
    },
  },
  async created() {
    const data = await getMiceInfo();
    this.about = data.about || "";
  },
  async mounted() {
    const result = await getMiceInfo();
    this.videoUrl = result.video_url;
  },
  methods: {
    // 调用
    loadVideo() {
      let _this = this;
      let options = {};
      videoJS("my-video1", options, function onReady() {
        // 监听 点击播放按钮
        this.on("play", function () {
          _this.isPaly = true;
        });
        this.on("pause", function () {
          _this.isPaly = false;
        });
        this.on("ended", function () {
          _this.isPaly = false;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#about {
  padding: 25px 360px;
  color: #333;
  font-size: 16px;
  .base-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 50px;
    .base-info-left {
      // margin-right: 60px;
      // width: 700px;
      h1 {
        font-size: 32px;
        margin-bottom: 30px;
      }
      p {
        line-height: 30px;
        margin-bottom: 20px;
      }
      .base-info-row {
        display: flex;
        justify-content: space-between;
        color: #0054e1;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        .time,
        .address {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 32px;
          height: 32px;
        }
      }
    }
    .video-box {
      width: calc(100% - 700px);
      height: 295px;
      position: relative;
      .video-js {
        width: 100%;
        height: 100%;
      }
      /deep/.vjs-big-play-button {
        display: none;
      }
      img {
        position: absolute;
        width: 49px;
        top: calc(50% - 25px);
        left: calc(50% - 25px);
        pointer-events: none;
      }
    }
  }
}
</style>